<template>
  <div class="popup_wrap payment_reg_pop" style="width:1000px;height:600px;">
    <!-- popup_wrap :  style="width:1000px; height:600px;" 참고용 / 개발시 삭제 해주 세요. . -->
    <div class="popup_dim" id="payment_attach_pop">
      <component
        :is="customComponent"
        v-if="customComponent"
        @closeAttach="closeAttachPopup"
        :pay-no="params.paymentUser.payNo"
        :parent-upload-file-list="uploadFileList"
      />
    </div>
    <button
      type="button"
      class="layer_close"
      @click="$emit('close', { saveFlag: saveFlag })"
    >
      close
    </button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.ONIM099P060.001') }}</h1><!-- Payment -->
      <form id="frm_payment">
        <div class="content_box">
          <!-- content_box -->
          <table class="tbl_row">
            <colgroup>
              <col width="160"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.ONIM099P060.002') }}</th><!-- Payment No. -->
                <td>{{ params.paymentUser.payNo }}</td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONIM099P060.003') }}</th><!-- Name / Tel / email -->
                <td>
                  <div class="tbl_form">
                    <span class="dv col_3">
                      <input
                        v-model="params.paymentUser.shirPicNm"
                        id="shir_pic_nm"
                        type="text"
                      />
                    </span>
                    <span class="dv col_3">
                      <input
                        v-model="params.paymentUser.shirTelNo"
                        id="shir_tel_no"
                        type="text"
                      />
                    </span>
                    <span class="dv">
                      <input
                        v-model="params.paymentUser.shirEmlAddr"
                        id="shir_eml_addr"
                        type="text"
                      >
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONIM099P060.004') }}</th><!-- Remark -->
                <td>
                  <textarea
                    v-model="params.paymentUser.rmk"
                    id="rmk"
                  />
                </td>
              </tr>
              <tr v-show="memberDetail.userCtrCd === 'ID'">
                <th>{{ $t('msg.ONIM099P060.005') }}</th><!-- Upload -->
                <td>
                  <div style="float:left; width:20%">
                    <span class="color_black font_light">
                      <input
                        v-model="copy"
                        type="checkbox"
                        :id="'checkbox_copy'"
                        true-value="Y"
                        false-value="N"
                        @click="handleClick($event)"
                        :disabled="copy==='N'"
                      >
                      <label for="checkbox_copy"><span style="font-size : 12px;">COPY B/L</span></label>
                    </span>

                    <span v-if="params.payStsCd === '' || params.payStsCd === '01'">
                      <div class="div-file">
                        <button @click.prevent="handleInputDivClick('copy')">{{ $t('msg.ONEX050G020.023') }}</button>
                        <!-- <span style="padding-right: 10px;">{{ params.fileNm }}</span> -->
                        <div v-if="uploadFileList.length > 0">
                          <span v-for="file in uploadFileList" :key="file.fileId">
                            <template v-if="file.kind === 'copy'">
                              <span class="tbl_icon attach"></span>
                              <a class="text_link">{{ file.fileNm }}</a>
                              <a class="tbl_icon attdel" @click="removeUploadFile('copy')">X</a>
                            </template>
                          </span>
                        </div>
                      </div>
                      <input ref="uploadFile_copy" @change="fnFileUpload('copy', '01')" type="file" id="inpFile_copy" style="width: 0; height: 0;" />
                    </span>
                  </div>

                  <div style="float:left; width:20%">
                    <span class="color_black">
                      <input
                        v-model="proof"
                        type="checkbox"
                        :id="'checkbox_proof'"
                        true-value="Y"
                        false-value="N"
                        @click="handleClick($event)"
                        :disabled="proof==='N'"
                      >
                      <label for="checkbox_proof"><span style="font-size : 12px;">PROOF_OF_PAYMENT</span></label>
                    </span>
                    <span v-if="params.payStsCd === '' || params.payStsCd === '01'">
                      <div class="div-file">
                        <button @click.prevent="handleInputDivClick('proof')">{{ $t('msg.ONEX050G020.023') }}</button>
                        <!-- <span style="padding-right: 10px;">{{ params.fileNm }}</span> -->
                        <div v-if="uploadFileList.length > 0">
                          <span v-for="file in uploadFileList" :key="file.fileId">
                            <template v-if="file.kind === 'proof'">
                              <span class="tbl_icon attach"></span>
                              <a class="text_link">{{ file.fileNm }}</a>
                              <a class="tbl_icon attdel" @click="removeUploadFile('proof')">X</a>
                            </template>
                          </span>
                        </div>
                      </div>
                      <input ref="uploadFile_proof" @change="fnFileUpload('proof', '02')" type="file" id="inpFile_proof" style="width: 0; height: 0;" />
                    </span>
                  </div>

                  <div style="float:left; width:20%">
                    <span class="color_black">
                      <input
                        v-model="loi"
                        type="checkbox"
                        :id="'checkbox_loi'"
                        true-value="Y"
                        false-value="N"
                        @click="handleClick($event)"
                        :disabled="loi==='N'"
                      >
                      <label for="checkbox_loi"><span style="font-size : 12px;">LOI + POA/CL</span></label>
                    </span>
                    <span v-if="params.payStsCd === '' || params.payStsCd === '01'">
                      <div class="div-file">
                        <button @click.prevent="handleInputDivClick('loi')">{{ $t('msg.ONEX050G020.023') }}</button>
                        <!-- <span style="padding-right: 10px;">{{ params.fileNm }}</span> -->
                        <div v-if="uploadFileList.length > 0">
                          <span v-for="file in uploadFileList" :key="file.fileId">
                            <template v-if="file.kind === 'loi'">
                              <span class="tbl_icon attach"></span>
                              <a class="text_link">{{ file.fileNm }}</a>
                              <a class="tbl_icon attdel" @click="removeUploadFile('loi')">X</a>
                            </template>
                          </span>
                        </div>
                      </div>
                      <input ref="uploadFile_loi" @change="fnFileUpload('loi', '03')" type="file" id="inpFile_loi" style="width: 0; height: 0;" />
                    </span>
                  </div>

                  <!-- <div style="float:left; width:20%">
                    <span class="color_black">
                      <input
                        v-model="poacl"
                        type="checkbox"
                        :id="'checkbox_poacl'"
                        true-value="Y"
                        false-value="N"
                        @click="handleClick($event)"
                        :disabled="poacl==='N'"
                      >
                      <label for="checkbox_poacl"><span style="font-size : 12px;">POA/CL</span></label>
                    </span>
                    <span v-if="params.payStsCd === '' || params.payStsCd === '01'">
                      <div class="div-file">
                        <button @click.prevent="handleInputDivClick('poacl')">{{ $t('msg.ONEX050G020.023') }}</button>
                        <div v-if="uploadFileList.length > 0">
                          <span v-for="file in uploadFileList" :key="file.fileId">
                            <template v-if="file.kind === 'poacl'">
                              <span class="tbl_icon attach"></span>
                              <a class="text_link">{{ file.fileNm }}</a>
                              <a class="tbl_icon attdel" @click="removeUploadFile('poacl')">X</a>
                            </template>
                          </span>
                        </div>
                      </div>
                      <input ref="uploadFile_poacl" @change="fnFileUpload('poacl', '04')" type="file" id="inpFile_poacl" style="width: 0; height: 0;" />
                    </span>
                  </div> -->

                  <div style="float:left; width:20%">
                    <span class="color_black">
                      <input
                        v-model="ktp"
                        type="checkbox"
                        :id="'checkbox_ktp'"
                        true-value="Y"
                        false-value="N"
                        @click="handleClick($event)"
                        :disabled="ktp==='N'"
                      >
                      <label for="checkbox_ktp"><span style="font-size : 12px;">KTP + NPWP</span></label>
                    </span>
                    <span v-if="params.payStsCd === '' || params.payStsCd === '01'">
                      <div class="div-file">
                        <button @click.prevent="handleInputDivClick('ktp')">{{ $t('msg.ONEX050G020.023') }}</button>
                        <!-- <span style="padding-right: 10px;">{{ params.fileNm }}</span> -->
                        <div v-if="uploadFileList.length > 0">
                          <span v-for="file in uploadFileList" :key="file.fileId">
                            <template v-if="file.kind === 'ktp'">
                              <span class="tbl_icon attach"></span>
                              <a class="text_link">{{ file.fileNm }}</a>
                              <a class="tbl_icon attdel" @click="removeUploadFile('ktp')">X</a>
                            </template>
                          </span>
                        </div>
                      </div>
                      <input ref="uploadFile_ktp" @change="fnFileUpload('ktp', '05')" type="file" id="inpFile_ktp" style="width: 0; height: 0;" />
                    </span>
                  </div>

                  <div style="float:left; width:20%">
                    <span class="color_black">
                      <span style="font-size : 12px;">Payment transferred to</span>
                    </span>
                    <span v-if="params.payStsCd === '' || params.payStsCd === '01'">
                      <input v-model="params.paymentUser.reqBnkBrncCd" type="radio" id="reqBnkBrncCd_mdr" name="reqBnkBrncCd" value="JKT001">
                      <label for="reqBnkBrncCd_mdr"><span>MANDIRI</span></label>
                      <input v-model="params.paymentUser.reqBnkBrncCd" type="radio" id="reqBnkBrncCd_bca" name="reqBnkBrncCd" value="BCAJKT">
                      <label for="reqBnkBrncCd_bca"><span></span>BCA</label>
                    </span>
                  </div>
                </td>
              </tr>

              <tr v-show="memberDetail.userCtrCd !== 'ID'">
                <th>{{ $t('msg.ONIM099P060.005') }}</th>
                <td>
                  <span
                    v-if="params.paymentUser.evdcmtrUpldYn === 'Y'"
                    class="wid30 d-inline-block"
                  >Y</span>
                  <a
                    v-if="params.payStsCd === '' || params.payStsCd === '01'"
                    class="button sm"
                    href="#"
                    @click.prevent="fnPayUpload('payment-attach-pop')"
                  >{{ $t('msg.ONIM099P060.006') }}</a>
                  <span class="position_relative" style="margin-left: 10px;">
                    <button type="button" class="tbl_icon help" @click="showTooltip = !showTooltip">help</button>
                    <div v-show="showTooltip" class="tooltip_wrap short position_absolute" style="width:531px; left:0; top:24px;">
                      <div class="cont">
                        <button class="close" @click="showTooltip = false">close</button>
                        <b>DOCUMENT THAT ARE REQUIRED TO BE UPLOADED</b>
                        <ul class="bul_list_sm t2">
                          <li style="font-size: 10px">
                            User Consignee : Copy MBL,LOI,KTP,NPWP,Bukti Transfer,Surat Pengantar/Covering letter(From Notify Party)
                          </li>
                          <li style="font-size: 10px">
                            User Notify : Copy MBL,LOI,KTP,NPWP,Bukti Transfer,Surat Kuasa/Power of attorney(From Cgnee.)
                          </li>
                          <li style="font-size: 10px">
                            User Direct Cgnee.(Notify Same as Cgnee) : Copy MBL,LOI,KTP,NPWP dan Bukti Transfer
                          </li>
                        </ul>
                      </div>
                    </div>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <h2 class="content_title">{{ $t('msg.ONIM099P060.007') }}</h2><!-- Freight information -->

          <table class="tbl_col">
            <colgroup>
              <col style="width:12%">
              <col style="width:26%">
              <col style="width:8%">
              <col style="width:10%">
              <col style="width:8%">
              <col style="width:8%">
              <col style="width:8%">
              <col style="width:10%">
              <col style="width:10%">
            </colgroup>
            <thead>
              <tr>
                <th>{{ $t('msg.ONIM099P060.008') }}</th><!-- B/L No. -->
                <th>{{ $t('msg.ONIM099P060.009') }}</th><!-- Freight & Charge -->
                <th>{{ $t('msg.ONIM099P060.010') }}</th><!-- CUR -->
                <th>{{ $t('msg.ONIM099P060.011') }}</th><!-- Rate -->
                <th>{{ $t('msg.ONIM099P060.012') }}</th><!-- Unit -->
                <th>{{ $t('msg.ONIM099P060.013') }}</th><!-- Per -->
                <th>{{ $t('msg.ONIM099P060.014') }}</th><!-- Ex.Rate -->
                <th>{{ $t('msg.ONIM099P060.015') }}</th><!-- Local CUR -->
                <th>{{ $t('msg.ONIM099P060.016') }}</th><!-- Local Amount -->
              </tr>
            </thead>
            <tbody v-if="payList !== undefined && payList.length > 0">
              <tr
                v-for="(pay, idx) in payList"
                :key="`${idx}_${pay.blNo}_${pay.frtCd}`"
              >
                <td
                  v-if="pay.rowSpanBlNo === 'Y'"
                  :rowspan="pay.blCount"
                >
                  {{ pay.blNo }}
                </td>
                <td
                  :class="['text_left', pay.rowSpanBlNo !== 'Y' ? 'border_left' : '']"
                >
                  {{ pay.frtNm }}
                </td>
                <td>{{ pay.curCd }}</td>
                <td class="text_right">{{ pay.totAmt }}</td>
                <td>{{ pay.frtUnitNm }}</td>
                <td>{{ pay.rton }}</td>
                <td>{{ pay.exrt }}</td>
                <td>{{ pay.loclCurCd }}</td>
                <td class="text_right">{{ pay.locAmt }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="9">{{ $t('msg.ONIM099P060.017') }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="8">{{ $t('msg.ONIM099P060.018') }}</th>
                <th class="text_right">{{ payTotal }}</th>
              </tr>
            </tfoot>
          </table>
        </div><!-- content_box // -->
      </form>

      <div class="mt10 text_center">
        <template v-if="this.$ekmtcCommon.isEmpty(params.payStsCd) || params.payStsCd === '01'">
          <a
            class="button blue lg"
            href="#"
            @click.prevent="fnInsert('save')"
          >{{ $t('msg.ONIM099P060.019') }}</a><!-- Save -->
          <a
            class="button blue lg"
            href="#"
            @click.prevent="fnInsert('submit')"
          >{{ $t('msg.ONIM099P060.020') }}</a><!-- Submit -->
        </template>
        <a
          v-if="this.$ekmtcCommon.isEmpty(params.payStsCd) && newPay !== 'Y' && params.paySubmitYn !== 'Y'"
          class="button gray lg"
          href="#"
          @click.prevent="fnDelete()"
        >{{ $t('msg.ONIM099P060.021') }}</a><!-- Delete -->
        <a
          class="button gray lg"
          href="#"
          @click.prevent="$emit('close', { saveFlag: saveFlag })"
        >{{ $t('msg.ONIM099P060.022') }}</a><!-- Close -->
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import payment from '@/api/rest/trans/payment'
import commons from '@/api/services/commons'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'PaymentRegPop',
  components: {
    'payment-attach-pop': () => import('@/pages/trans/popup/PaymentAttachPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          linkPayNo: '',
          linkYn: 'N',
          payStsCd: ''
        }
      }
    }
  },
  data: function () {
    return {
      customComponent: null,
      params: {
        blNo: '',
        linkPayNo: '',
        linkYn: 'N',
        payStsCd: '',
        paymentUser: {
          payNo: '',
          blNo: '',
          shirPicNm: '',
          shirTelNo: '',
          shirEmlAddr: '',
          evdcmtrUpldYn: '',
          rmk: '',
          reqBnkBrncCd: ''
        },
        paySubmitYn: 'N'
      },
      newPay: '',
      oldPayYn: '',
      payList: [],
      // payReqYn: '',
      payTotal: 0,
      saveFlag: false,
      uploadFileList: [],
      showTooltip: false,
      tempFile: {
        id: '',
        msg: '',
        kind: ''
      },
      filename: '',
      formData: null,
      copy: 'N',
      proof: 'N',
      loi: 'N',
      poacl: 'N',
      ktp: 'N'
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.params = { ...this.params, ...this.parentInfo }
    this.init()
  },
  methods: {
    async init () {
      await this.getPaymentList()
      await this.getPaymentAttachList()
    },
    async getPaymentList () {
      const blNo = this.params.blNo

      if (!this.$ekmtcCommon.isEmpty(blNo)) {
        await payment.popPaymentList(this.params)
          .then(response => {
            const resp = response.data
            const payList = resp.payList
            const paymentUser = resp.paymentUser

            this.payList = []

            if (payList !== undefined && payList.length > 0) {
              for (let i = 0; i < payList.length; i++) {
                let item = payList[i]

                if (i === 0 || item.blNo !== payList[i - 1].blNo) {
                  item.rowSpanBlNo = 'Y'
                }

                this.payTotal += Number(item.locAmt)

                item.exrt = this.$ekmtcCommon.isNotEmpty(item.exrt) && Number(item.exrt) > 0 ? String(item.exrt).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'
                item.locAmt = this.$ekmtcCommon.isNotEmpty(item.locAmt) && Number(item.locAmt) > 0 ? String(item.locAmt).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'
                item.rton = this.$ekmtcCommon.isNotEmpty(item.rton) && Number(item.rton) > 0 ? String(item.rton).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'
                item.totAmt = this.$ekmtcCommon.isNotEmpty(item.totAmt) && Number(item.totAmt) > 0 ? String(item.totAmt).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'
              }

              this.payList = payList

              this.payTotal = String(this.payTotal).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }

            if (paymentUser !== undefined) {
              this.params.paymentUser = { ...this.params.paymentUser, ...paymentUser }
            }

            this.newPay = resp.newPay
            this.oldPayYn = resp.oldPayYn
            // this.payReqYn = resp.payReqYn
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    // Payment 입력항목 validation check
    fnValidationAll (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_payment')
      }

      let isAllOk = true

      if (!this.fnValidationName(frm)) {
        // 'Name' 체크
        isAllOk = false
      } else if (!this.fnValidationTelNo(frm)) {
        // 'Tel' 체크
        isAllOk = false
      } else if (!this.fnValidationEmail(frm)) {
        // 'email' 체크
        isAllOk = false
      } else if (!this.fnValidationRmk(frm)) {
        // 'Remark' 체크
        isAllOk = false
      } else if (!this.fnValidationFile(frm)) {
        // 첨부파일 체크
        isAllOk = false
      } else if (!this.fnValidationbnkBrnc(frm)) {
        // 첨부파일 체크
        isAllOk = false
      }

      return isAllOk
    },
    fnValidationName (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_payment')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      // 'Name' 체크
      elem = frm.querySelector('#shir_pic_nm')
      if (this.$ekmtcCommon.isEmpty(this.params.paymentUser.shirPicNm)) {
        isOk = false
        msg = this.$t('msg.ONIM099P060.023') // Please Input Name.
        this.$ekmtcCommon.alertDefault(msg)
      }

      return isOk
    },
    fnValidationTelNo (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_payment')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      // 'Tel' 체크
      elem = frm.querySelector('#shir_tel_no')
      if (this.$ekmtcCommon.isEmpty(this.params.paymentUser.shirTelNo)) {
        isOk = false
        msg = this.$t('msg.ONIM099P060.024') // Please Input Tel.
        this.$ekmtcCommon.alertDefault(msg)
      }

      return isOk
    },
    fnValidationEmail (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_payment')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      // 'email' 체크
      elem = frm.querySelector('#shir_eml_addr')
      if (this.$ekmtcCommon.isEmpty(this.params.paymentUser.shirEmlAddr)) {
        isOk = false
        msg = this.$t('msg.ONIM099P060.025') // Please Input E-mail.
        this.$ekmtcCommon.alertDefault(msg)
      }

      return isOk
    },
    fnValidationRmk (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_payment')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      // 'Remark' 체크
      elem = frm.querySelector('#rmk')
      if (!this.$ekmtcCommon.checkTextByte(this.params.paymentUser.rmk, 2000)) {
        isOk = false
        msg = this.$t('msg.ONIM099P060.026') // Remark - Too much content.
        this.$ekmtcCommon.alertDefault(msg)
      }

      return isOk
    },
    fnValidationFile (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_payment')
      }

      let isOk = true
      let msg = this.copy === 'N' ? 'COPY B/L' : this.proof === 'N' ? 'PROOF_OF_PAYMENT' : this.loi === 'N' ? 'LOI' : 'KTP/NPWP'

      // 'Remark' 체크
      if (this.copy === 'N' || this.proof === 'N' || this.loi === 'N' || this.ktp === 'N') {
        isOk = false
        msg = `Please attach ${msg} File.` //
        this.$ekmtcCommon.alertDefault(msg)
      }

      return isOk
    },
    fnValidationbnkBrnc (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_payment')
      }

      let isOk = true
      let msg = 'Please choose the Payment transferred to.'

      // 'Remark' 체크
      if (this.$ekmtcCommon.isEmpty(this.params.paymentUser.reqBnkBrncCd)) {
        isOk = false
        this.$ekmtcCommon.alertDefault(msg)
      }

      return isOk
    },
    // flag
    // 'save' -> 저장
    // 'submit' -> 제출
    async fnInsert (flag) {
      // if (flag === 'submit' && this.params.paySubmitYn === 'Y') {
      if (this.params.paySubmitYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM099P060.027')) // Already been confirmed.
        return
      }

      let blNoStr = ''
      let locAmtYn = ''

      if (this.payList.length === 0) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM099P060.017')) // No Data
        return
      } else {
        for (let i = 0; i < this.payList.length; i++) {
          const pay = this.payList[i]

          if (flag === 'submit' && pay.exrt === '0') {
            locAmtYn = 'Y'
            break
          }
          if (blNoStr.indexOf(pay.blNo) === -1) {
            blNoStr += pay.blNo + ':'
          }
        }
      }

      if (locAmtYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM099P060.028')) // Please Check Ex.Rate.
        return
      }

      if (flag === 'submit' && this.params.paymentUser.evdcmtrUpldYn !== 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM099P060.029')) // Please Check File Upload.
        return
      }

      const frm = document.querySelector('#frm_payment')

      const isOk = this.fnValidationAll(frm)

      if (!isOk) {
        return
      }

      this.params.blNo = blNoStr
      // save - Would you like to register?
      // submit - Would you like to confirm?
      const msg = flag === 'save' ? this.$t('tem.CMAT024') : this.$t('tem.CMAT021')

      if (await this.$ekmtcCommon.asyncAlertMessage({ message: msg, useConfirmBtn: true })) {
        if (flag === 'submit') {
          this.params.paySubmitYn = 'Y'
        }

        // 인도네시아 파일첨부
        if (this.memberDetail.userCtrCd === 'ID') {
          await this.savePaymentFile()
        }

        await payment.InsertPayment(this.params)
          .then(response => {
            const respCode = response.headers.respcode

            if (respCode !== undefined) {
              let msg = ''

              if (respCode === 'C0000') {
                this.saveFlag = true

                // Completed to save.
                msg = this.$t('tem.CMAT013')
                this.newPay = 'N'
              } else {
                this.saveFlag = false

                if (respCode === 'T0010') {
                  msg = this.$t('msg.ONIM099P060.027') // Already been confirmed.
                }
                if (respCode === 'T0011') {
                  msg = this.$t('msg.ONIM099P060.030') // Failed to save.
                }
              }

              this.$ekmtcCommon.alertDefault(msg)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    async fnDelete () {
      // Would you like to delete?
      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('tem.CMAT023'), useConfirmBtn: true })) {
        payment.payDelete(this.params)
          .then(response => {
            const respCode = response.headers.respcode

            if (respCode !== undefined) {
              let msg = ''

              if (respCode === 'C0000') {
                this.saveFlag = true

                msg = this.$t('msg.ONIM099P060.031') // Completed to delete.
                this.newPay = 'Y'
              } else {
                this.saveFlag = false

                if (respCode === 'T0010') {
                  msg = this.$t('msg.ONIM099P060.027') // Already been confirmed.
                }
                if (respCode === 'T0011') {
                  msg = this.$t('msg.ONIM099P060.032') // Failed to delete.
                }
              }

              this.$ekmtcCommon.alertDefault(msg)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    fnPayUpload (compNm) {
      const frm = document.querySelector('#frm_payment')

      const isOk = this.fnValidationAll(frm)

      if (!isOk) {
        return
      }

      this.openAttachPopup(compNm)
    },
    openAttachPopup (compNm) {
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.payment_reg_pop .popup_dim')
    },
    closeAttachPopup (vo) {
      const elemAttachPopup = document.querySelector('#payment_attach_pop')
      this.customComponent = null

      elemAttachPopup.style.display = 'none'

      this.$ekmtcCommon.layerClose('.payment_reg_pop .popup_dim')

      if (vo) {
        this.params.paymentUser.evdcmtrUpldYn = (vo?.size ?? 0) > 0 ? 'Y' : 'N'
        this.getPaymentAttachList()
      }
    },
    async getPaymentAttachList () {
      if (this.params.paymentUser.payNo) {
        this.uploadFileList = []

        await payment.popInputPayUpload(this.params.paymentUser.payNo).then(response => {
          if (response.headers.respcode === 'C0000') {
            let fileList = response.data.filePayList

            if (fileList !== undefined) {
              fileList.forEach(file => {
                this.uploadFileList.push(
                  {
                    guid: file.reqRno + '_' + file.fileSeq,
                    originalName: file.fileNm,
                    size: file.fileSz,
                    fileId: 'http://trans.ekmtc:9000/trans/trans/file-download?reqRno=' + this.params.payNo + '&atchFileSeq=' + file.fileSeq + '&type=PAYMENT_ATTACH',
                    fileNm: file.fileNm,
                    reqRno: file.reqRno,
                    atchFileSeq: file.fileSeq,
                    kind: file.doReqCatCd === '01' ? 'copy' : file.doReqCatCd === '02' ? 'proof' : file.doReqCatCd === '03' ? 'loi' : 'ktp',
                    doReqCatCd: file.doReqCatCd
                  }
                )

                if (file.doReqCatCd === '01') {
                  this.copy = 'Y'
                } else if (file.doReqCatCd === '02') {
                  this.proof = 'Y'
                } else if (file.doReqCatCd === '03') {
                  this.loi = 'Y'
                } else if (file.doReqCatCd === '05') {
                  this.ktp = 'Y'
                }
              })
            }
          }
        })
      }
    },
    //파일첨부 클릭
    async fnFileUpload (kind, doReqCatCd) {
      const TH = this
      //file 타입 태그 클릭 이벤트 -> change이벤트 호출 -> 임시저장 -> excel upload 클릭시 file아이디 보내서 받은 것을 바이트스트림으로 변환후 받기
      const formData = new FormData()
      const inpFile = document.querySelector('#inpFile_' + kind)

      formData.append('files', inpFile.files[0])

      this.filename = inpFile.files[0].name
      this.params.fileNm = inpFile.files[0].name

      await commons.tempFileUpload(formData).then((result) => {
          const fileId = result.data.fileId
          const fileList = result.data.tempFileList[0]

          console.log('tempFileUpload @@@@@@', result)

          this.tempFile.id = fileId
          this.tempFile.msg = 'SELECT * FROM CM530I WHERE FILE_ID = ' + fileId + ';'
          this.tempFile.kind = kind
          this.params.fileChange = true

          this.uploadFileList.push(
            {
              fileId: fileId,
              kind: kind,
              fileNm: inpFile.files[0].name,
              atchFileSeq: fileList.fileSeq,
              newFlag: 'new',
              originalName: fileList.fileNm,
              size: fileList.fileSz,
              doReqCatCd
              // guid: file.reqRno + '_' + file.fileSeq,
              // reqRno: file.reqRno,
              // atchFileSeq: file.fileSeq
            }
          )

          this.params.paymentUser.evdcmtrUpldYn = (this.uploadFileList?.length ?? 0) > 0 ? 'Y' : 'N'

          TH.checkBoxY(kind)
      })
      .catch(err => {
        console.log(err)
      })
    },
    handleInputDivClick (kind) {
      switch (kind) {
        case 'copy':
          this.$refs.uploadFile_copy.click()
          break

        case 'proof':
          this.$refs.uploadFile_proof.click()
          break

        case 'loi':
          this.$refs.uploadFile_loi.click()
          break

        // case 'poacl':
        //   this.$refs.uploadFile_poacl.click()
        //   break

        case 'ktp':
          this.$refs.uploadFile_ktp.click()
          break
      }
    },
    removeUploadFile (kind) {
      // this.uploadFileList.splice(0, 1)

      const removeIndex = this.uploadFileList.findIndex(el => el.kind === kind)

      // 파일변수값 초기화
      this.filename = ''
      this.params.fileNm = ''
      this.tempFile = {}
      document.querySelector('#inpFile_' + kind).value = null

      switch (kind) {
          case 'copy':
            this.copy = 'N'
            break

          case 'proof':
            this.proof = 'N'
            break

          case 'loi':
            this.loi = 'N'
            break

          // case 'poacl':
          //   this.poacl = 'N'
          //   break

          case 'ktp':
            this.ktp = 'N'
            break
        }

      if (removeIndex >= 0) {
        this.uploadFileList.splice(removeIndex, 1)
      }
    },
    checkBoxY (kind) {
      switch (kind) {
        case 'copy':
          this.copy = 'Y'
          break

        case 'proof':
          this.proof = 'Y'
          break

        case 'loi':
          this.loi = 'Y'
          break

        // case 'poacl':
        //   this.poacl = 'Y'
        //   break

        case 'ktp':
          this.ktp = 'Y'
          break
      }
    },
    // 체크박스 Y값일 때, 클릭 막기
    handleClick (event) {
      event.preventDefault()
    },
    // 파일 저장
    async savePaymentFile () {
      // 실제 입력할 파일리스트를 담는다.
      this.params.uploadFileInfos = this.uploadFileList

      // 새로 추가된 파일은 json에 'newFlag' 값을 부여해준다. ('new'는 뒷단에서 예약어 이므로 새로 추가)
      // this.params.uploadFileInfos.map((item) => (item.newFlag = item.new))

      // 기존 파일은 fileId에 다운로드 링크가 들어 있어서
      // 뒷단에서 Data binding 할 때, 오류가 나서 값을 비워줘야 한다.
      this.params.uploadFileInfos.forEach(element => {
        if (element.newFlag === undefined || element.newFlag === '') {
          element.fileId = ''
        }
      })

      this.params.payNo = this.params.paymentUser.payNo
      const TH = this

      // 파일 저장
      await payment.popPayUpload(this.params).then(async response => {
        const result = response.data
        if (response.headers.respcode === 'C0000' && result !== undefined && result.resultCode === 'SUCC') {
          // 저장되었습니다.
          if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONIM099P070.005') })) {
            const vo = {
              size: TH.uploadFileList.length
              // modiFlag: TH.uploadFileList.length === TH.parentUploadFileList.length
            }
            // TH.$emit('closeAttach', vo)
          }
        } else {
          // 저장에 실패하였습니다. 관리자에게 문의해주시기 바랍니다.
          TH.$ekmtcCommon.alertDefault(TH.$t('msg.ONIM099P070.006'))
        }
      })
    }
  }
}
</script>
