var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap payment_reg_pop",
      staticStyle: { width: "1000px", height: "600px" },
    },
    [
      _c(
        "div",
        { staticClass: "popup_dim", attrs: { id: "payment_attach_pop" } },
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: {
                  "pay-no": _vm.params.paymentUser.payNo,
                  "parent-upload-file-list": _vm.uploadFileList,
                },
                on: { closeAttach: _vm.closeAttachPopup },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("close", { saveFlag: _vm.saveFlag })
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONIM099P060.001"))),
        ]),
        _c("form", { attrs: { id: "frm_payment" } }, [
          _c("div", { staticClass: "content_box" }, [
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.002")))]),
                  _c("td", [_vm._v(_vm._s(_vm.params.paymentUser.payNo))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.003")))]),
                  _c("td", [
                    _c("div", { staticClass: "tbl_form" }, [
                      _c("span", { staticClass: "dv col_3" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.params.paymentUser.shirPicNm,
                              expression: "params.paymentUser.shirPicNm",
                            },
                          ],
                          attrs: { id: "shir_pic_nm", type: "text" },
                          domProps: { value: _vm.params.paymentUser.shirPicNm },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.params.paymentUser,
                                "shirPicNm",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("span", { staticClass: "dv col_3" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.params.paymentUser.shirTelNo,
                              expression: "params.paymentUser.shirTelNo",
                            },
                          ],
                          attrs: { id: "shir_tel_no", type: "text" },
                          domProps: { value: _vm.params.paymentUser.shirTelNo },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.params.paymentUser,
                                "shirTelNo",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("span", { staticClass: "dv" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.params.paymentUser.shirEmlAddr,
                              expression: "params.paymentUser.shirEmlAddr",
                            },
                          ],
                          attrs: { id: "shir_eml_addr", type: "text" },
                          domProps: {
                            value: _vm.params.paymentUser.shirEmlAddr,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.params.paymentUser,
                                "shirEmlAddr",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.004")))]),
                  _c("td", [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.paymentUser.rmk,
                          expression: "params.paymentUser.rmk",
                        },
                      ],
                      attrs: { id: "rmk" },
                      domProps: { value: _vm.params.paymentUser.rmk },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.params.paymentUser,
                            "rmk",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.memberDetail.userCtrCd === "ID",
                        expression: "memberDetail.userCtrCd === 'ID'",
                      },
                    ],
                  },
                  [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.005")))]),
                    _c("td", [
                      _c(
                        "div",
                        { staticStyle: { float: "left", width: "20%" } },
                        [
                          _c(
                            "span",
                            { staticClass: "color_black font_light" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.copy,
                                    expression: "copy",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "checkbox_copy",
                                  "true-value": "Y",
                                  "false-value": "N",
                                  disabled: _vm.copy === "N",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.copy)
                                    ? _vm._i(_vm.copy, null) > -1
                                    : _vm._q(_vm.copy, "Y"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClick($event)
                                  },
                                  change: function ($event) {
                                    var $$a = _vm.copy,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? "Y" : "N"
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.copy = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.copy = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.copy = $$c
                                    }
                                  },
                                },
                              }),
                              _vm._m(1),
                            ]
                          ),
                          _vm.params.payStsCd === "" ||
                          _vm.params.payStsCd === "01"
                            ? _c("span", [
                                _c("div", { staticClass: "div-file" }, [
                                  _c(
                                    "button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleInputDivClick("copy")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.ONEX050G020.023"))
                                      ),
                                    ]
                                  ),
                                  _vm.uploadFileList.length > 0
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          _vm.uploadFileList,
                                          function (file) {
                                            return _c(
                                              "span",
                                              { key: file.fileId },
                                              [
                                                file.kind === "copy"
                                                  ? [
                                                      _c("span", {
                                                        staticClass:
                                                          "tbl_icon attach",
                                                      }),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "text_link",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(file.fileNm)
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "tbl_icon attdel",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeUploadFile(
                                                                "copy"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("X")]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]),
                                _c("input", {
                                  ref: "uploadFile_copy",
                                  staticStyle: { width: "0", height: "0" },
                                  attrs: { type: "file", id: "inpFile_copy" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.fnFileUpload("copy", "01")
                                    },
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { float: "left", width: "20%" } },
                        [
                          _c("span", { staticClass: "color_black" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.proof,
                                  expression: "proof",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "checkbox_proof",
                                "true-value": "Y",
                                "false-value": "N",
                                disabled: _vm.proof === "N",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.proof)
                                  ? _vm._i(_vm.proof, null) > -1
                                  : _vm._q(_vm.proof, "Y"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleClick($event)
                                },
                                change: function ($event) {
                                  var $$a = _vm.proof,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? "Y" : "N"
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 && (_vm.proof = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.proof = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.proof = $$c
                                  }
                                },
                              },
                            }),
                            _vm._m(2),
                          ]),
                          _vm.params.payStsCd === "" ||
                          _vm.params.payStsCd === "01"
                            ? _c("span", [
                                _c("div", { staticClass: "div-file" }, [
                                  _c(
                                    "button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleInputDivClick(
                                            "proof"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.ONEX050G020.023"))
                                      ),
                                    ]
                                  ),
                                  _vm.uploadFileList.length > 0
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          _vm.uploadFileList,
                                          function (file) {
                                            return _c(
                                              "span",
                                              { key: file.fileId },
                                              [
                                                file.kind === "proof"
                                                  ? [
                                                      _c("span", {
                                                        staticClass:
                                                          "tbl_icon attach",
                                                      }),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "text_link",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(file.fileNm)
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "tbl_icon attdel",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeUploadFile(
                                                                "proof"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("X")]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]),
                                _c("input", {
                                  ref: "uploadFile_proof",
                                  staticStyle: { width: "0", height: "0" },
                                  attrs: { type: "file", id: "inpFile_proof" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.fnFileUpload("proof", "02")
                                    },
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { float: "left", width: "20%" } },
                        [
                          _c("span", { staticClass: "color_black" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.loi,
                                  expression: "loi",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "checkbox_loi",
                                "true-value": "Y",
                                "false-value": "N",
                                disabled: _vm.loi === "N",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.loi)
                                  ? _vm._i(_vm.loi, null) > -1
                                  : _vm._q(_vm.loi, "Y"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleClick($event)
                                },
                                change: function ($event) {
                                  var $$a = _vm.loi,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? "Y" : "N"
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 && (_vm.loi = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.loi = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.loi = $$c
                                  }
                                },
                              },
                            }),
                            _vm._m(3),
                          ]),
                          _vm.params.payStsCd === "" ||
                          _vm.params.payStsCd === "01"
                            ? _c("span", [
                                _c("div", { staticClass: "div-file" }, [
                                  _c(
                                    "button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleInputDivClick("loi")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.ONEX050G020.023"))
                                      ),
                                    ]
                                  ),
                                  _vm.uploadFileList.length > 0
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          _vm.uploadFileList,
                                          function (file) {
                                            return _c(
                                              "span",
                                              { key: file.fileId },
                                              [
                                                file.kind === "loi"
                                                  ? [
                                                      _c("span", {
                                                        staticClass:
                                                          "tbl_icon attach",
                                                      }),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "text_link",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(file.fileNm)
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "tbl_icon attdel",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeUploadFile(
                                                                "loi"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("X")]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]),
                                _c("input", {
                                  ref: "uploadFile_loi",
                                  staticStyle: { width: "0", height: "0" },
                                  attrs: { type: "file", id: "inpFile_loi" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.fnFileUpload("loi", "03")
                                    },
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { float: "left", width: "20%" } },
                        [
                          _c("span", { staticClass: "color_black" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.ktp,
                                  expression: "ktp",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "checkbox_ktp",
                                "true-value": "Y",
                                "false-value": "N",
                                disabled: _vm.ktp === "N",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.ktp)
                                  ? _vm._i(_vm.ktp, null) > -1
                                  : _vm._q(_vm.ktp, "Y"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleClick($event)
                                },
                                change: function ($event) {
                                  var $$a = _vm.ktp,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? "Y" : "N"
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 && (_vm.ktp = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.ktp = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.ktp = $$c
                                  }
                                },
                              },
                            }),
                            _vm._m(4),
                          ]),
                          _vm.params.payStsCd === "" ||
                          _vm.params.payStsCd === "01"
                            ? _c("span", [
                                _c("div", { staticClass: "div-file" }, [
                                  _c(
                                    "button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleInputDivClick("ktp")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.ONEX050G020.023"))
                                      ),
                                    ]
                                  ),
                                  _vm.uploadFileList.length > 0
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          _vm.uploadFileList,
                                          function (file) {
                                            return _c(
                                              "span",
                                              { key: file.fileId },
                                              [
                                                file.kind === "ktp"
                                                  ? [
                                                      _c("span", {
                                                        staticClass:
                                                          "tbl_icon attach",
                                                      }),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "text_link",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(file.fileNm)
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "tbl_icon attdel",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeUploadFile(
                                                                "ktp"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("X")]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]),
                                _c("input", {
                                  ref: "uploadFile_ktp",
                                  staticStyle: { width: "0", height: "0" },
                                  attrs: { type: "file", id: "inpFile_ktp" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.fnFileUpload("ktp", "05")
                                    },
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { float: "left", width: "20%" } },
                        [
                          _vm._m(5),
                          _vm.params.payStsCd === "" ||
                          _vm.params.payStsCd === "01"
                            ? _c("span", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.params.paymentUser.reqBnkBrncCd,
                                      expression:
                                        "params.paymentUser.reqBnkBrncCd",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    id: "reqBnkBrncCd_mdr",
                                    name: "reqBnkBrncCd",
                                    value: "JKT001",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.params.paymentUser.reqBnkBrncCd,
                                      "JKT001"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.params.paymentUser,
                                        "reqBnkBrncCd",
                                        "JKT001"
                                      )
                                    },
                                  },
                                }),
                                _vm._m(6),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.params.paymentUser.reqBnkBrncCd,
                                      expression:
                                        "params.paymentUser.reqBnkBrncCd",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    id: "reqBnkBrncCd_bca",
                                    name: "reqBnkBrncCd",
                                    value: "BCAJKT",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.params.paymentUser.reqBnkBrncCd,
                                      "BCAJKT"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.params.paymentUser,
                                        "reqBnkBrncCd",
                                        "BCAJKT"
                                      )
                                    },
                                  },
                                }),
                                _vm._m(7),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.memberDetail.userCtrCd !== "ID",
                        expression: "memberDetail.userCtrCd !== 'ID'",
                      },
                    ],
                  },
                  [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.005")))]),
                    _c("td", [
                      _vm.params.paymentUser.evdcmtrUpldYn === "Y"
                        ? _c("span", { staticClass: "wid30 d-inline-block" }, [
                            _vm._v("Y"),
                          ])
                        : _vm._e(),
                      _vm.params.payStsCd === "" || _vm.params.payStsCd === "01"
                        ? _c(
                            "a",
                            {
                              staticClass: "button sm",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnPayUpload("payment-attach-pop")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.006")))]
                          )
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          staticClass: "position_relative",
                          staticStyle: { "margin-left": "10px" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon help",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.showTooltip = !_vm.showTooltip
                                },
                              },
                            },
                            [_vm._v("help")]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showTooltip,
                                  expression: "showTooltip",
                                },
                              ],
                              staticClass:
                                "tooltip_wrap short position_absolute",
                              staticStyle: {
                                width: "531px",
                                left: "0",
                                top: "24px",
                              },
                            },
                            [
                              _c("div", { staticClass: "cont" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "close",
                                    on: {
                                      click: function ($event) {
                                        _vm.showTooltip = false
                                      },
                                    },
                                  },
                                  [_vm._v("close")]
                                ),
                                _c("b", [
                                  _vm._v(
                                    "DOCUMENT THAT ARE REQUIRED TO BE UPLOADED"
                                  ),
                                ]),
                                _vm._m(8),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.ONIM099P060.007"))),
            ]),
            _c("table", { staticClass: "tbl_col" }, [
              _vm._m(9),
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.008")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.009")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.010")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.011")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.012")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.013")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.014")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.015")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.016")))]),
                ]),
              ]),
              _vm.payList !== undefined && _vm.payList.length > 0
                ? _c(
                    "tbody",
                    _vm._l(_vm.payList, function (pay, idx) {
                      return _c(
                        "tr",
                        { key: `${idx}_${pay.blNo}_${pay.frtCd}` },
                        [
                          pay.rowSpanBlNo === "Y"
                            ? _c("td", { attrs: { rowspan: pay.blCount } }, [
                                _vm._v(" " + _vm._s(pay.blNo) + " "),
                              ])
                            : _vm._e(),
                          _c(
                            "td",
                            {
                              class: [
                                "text_left",
                                pay.rowSpanBlNo !== "Y" ? "border_left" : "",
                              ],
                            },
                            [_vm._v(" " + _vm._s(pay.frtNm) + " ")]
                          ),
                          _c("td", [_vm._v(_vm._s(pay.curCd))]),
                          _c("td", { staticClass: "text_right" }, [
                            _vm._v(_vm._s(pay.totAmt)),
                          ]),
                          _c("td", [_vm._v(_vm._s(pay.frtUnitNm))]),
                          _c("td", [_vm._v(_vm._s(pay.rton))]),
                          _c("td", [_vm._v(_vm._s(pay.exrt))]),
                          _c("td", [_vm._v(_vm._s(pay.loclCurCd))]),
                          _c("td", { staticClass: "text_right" }, [
                            _vm._v(_vm._s(pay.locAmt)),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _c("tbody", [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "9" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONIM099P060.017"))),
                      ]),
                    ]),
                  ]),
              _c("tfoot", [
                _c("tr", [
                  _c("th", { attrs: { colspan: "8" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.ONIM099P060.018"))),
                  ]),
                  _c("th", { staticClass: "text_right" }, [
                    _vm._v(_vm._s(_vm.payTotal)),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "mt10 text_center" },
          [
            this.$ekmtcCommon.isEmpty(_vm.params.payStsCd) ||
            _vm.params.payStsCd === "01"
              ? [
                  _c(
                    "a",
                    {
                      staticClass: "button blue lg",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.fnInsert("save")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.019")))]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "button blue lg",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.fnInsert("submit")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.020")))]
                  ),
                ]
              : _vm._e(),
            this.$ekmtcCommon.isEmpty(_vm.params.payStsCd) &&
            _vm.newPay !== "Y" &&
            _vm.params.paySubmitYn !== "Y"
              ? _c(
                  "a",
                  {
                    staticClass: "button gray lg",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.fnDelete()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.021")))]
                )
              : _vm._e(),
            _c(
              "a",
              {
                staticClass: "button gray lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("close", { saveFlag: _vm.saveFlag })
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.022")))]
            ),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "160" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "checkbox_copy" } }, [
      _c("span", { staticStyle: { "font-size": "12px" } }, [
        _vm._v("COPY B/L"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "checkbox_proof" } }, [
      _c("span", { staticStyle: { "font-size": "12px" } }, [
        _vm._v("PROOF_OF_PAYMENT"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "checkbox_loi" } }, [
      _c("span", { staticStyle: { "font-size": "12px" } }, [
        _vm._v("LOI + POA/CL"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "checkbox_ktp" } }, [
      _c("span", { staticStyle: { "font-size": "12px" } }, [
        _vm._v("KTP + NPWP"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "color_black" }, [
      _c("span", { staticStyle: { "font-size": "12px" } }, [
        _vm._v("Payment transferred to"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "reqBnkBrncCd_mdr" } }, [
      _c("span", [_vm._v("MANDIRI")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "reqBnkBrncCd_bca" } }, [
      _c("span"),
      _vm._v("BCA"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "bul_list_sm t2" }, [
      _c("li", { staticStyle: { "font-size": "10px" } }, [
        _vm._v(
          " User Consignee : Copy MBL,LOI,KTP,NPWP,Bukti Transfer,Surat Pengantar/Covering letter(From Notify Party) "
        ),
      ]),
      _c("li", { staticStyle: { "font-size": "10px" } }, [
        _vm._v(
          " User Notify : Copy MBL,LOI,KTP,NPWP,Bukti Transfer,Surat Kuasa/Power of attorney(From Cgnee.) "
        ),
      ]),
      _c("li", { staticStyle: { "font-size": "10px" } }, [
        _vm._v(
          " User Direct Cgnee.(Notify Same as Cgnee) : Copy MBL,LOI,KTP,NPWP dan Bukti Transfer "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "26%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }